// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, get, set } from 'firebase/database';

// Конфигурация Firebase
const firebaseConfig = {
    apiKey: "AIzaSyB95Rp0pvwjcFi0dHEvvrRh0svfTkuL7MA",
    authDomain: "ala-laundry-902e5.firebaseapp.com",
    databaseURL: "https://ala-laundry-902e5-default-rtdb.firebaseio.com",
    projectId: "ala-laundry-902e5",
    storageBucket: "ala-laundry-902e5.appspot.com",
    messagingSenderId: "844364387376",
    appId: "1:844364387376:web:6e5cc90231e6186a1ec9f5",
    measurementId: "G-RJYCMM2PLR"
};

// Инициализация приложения Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

// Лог для проверки инициализации
console.log('Firebase Initialized:', app);

// Функция для тестовой записи данных
function writeTestData() {
    const testRef = ref(database, 'testNode');
    set(testRef, {
        testField: 'Hello, Firebase!'
    })
    .then(() => {
        console.log('Test data written successfully!');
    })
    .catch((error) => {
        console.error('Error writing test data:', error);
    });
}

// Функция для тестового чтения данных
function readTestData() {
    const testRef = ref(database, 'testNode');
    get(testRef)
    .then((snapshot) => {
        if (snapshot.exists()) {
            console.log('Test data from Firebase:', snapshot.val());
        } else {
            console.log('No test data available');
        }
    })
    .catch((error) => {
        console.error('Error reading test data:', error);
    });
}

// Вызов функций для тестирования
writeTestData();
readTestData();

export { database };
