// src/App.js
import React, { useEffect, useState } from 'react';
import { database } from './firebase';
import { ref, update, onValue } from 'firebase/database';
import { Typography, Button, Box, Paper, Snackbar, Alert } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import './App.css';
import { SpeedInsights } from "@vercel/speed-insights/react"


function App() {
  const [searchParams] = useSearchParams();
  const machineId = searchParams.get('machineId');
  const [machineData, setMachineData] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false); // Для управления открытием алертов
  const [alertMessage, setAlertMessage] = useState(''); // Сообщение алерта
  const [alertSeverity, setAlertSeverity] = useState('success'); // Тип алерта ('success', 'error', 'warning', 'info')

  useEffect(() => {
    if (!machineId) {
      setAlertMessage('Кір жуғыш машинаның ID көрсетілмеген. URL мекенжайында жарамды машина ID көрсетіңіз.');
      setAlertSeverity('error');
      setAlertOpen(true);
      return;
    }

    const machineRef = ref(database, `/${machineId}`);
    onValue(machineRef, (snapshot) => {
      const data = snapshot.val();
      setMachineData(data);
    });
  }, [machineId]);

  const handleStart = () => {
    if (!machineId) {
      setAlertMessage('Жарамсыз ID');
      setAlertSeverity('error');
      setAlertOpen(true);
      return;
    }

    if (machineData?.output.isDoorOpen) {
      // Если машина уже занята, показываем предупреждение
      setAlertMessage('Кір жуғыш машина бос емес, аяқталуын күтіңіз.');
      setAlertSeverity('warning');
      setAlertOpen(true);
      return;
    }

    const updates = {};
    updates[`/${machineId}/input/isStarted/isStarted`] = 2;

    update(ref(database), updates)
      .then(() => {
        setAlertMessage(`№${machineId} кір жуғыш машина сәтті іске қосылды.`);
        setAlertSeverity('success');
        setAlertOpen(true);
      })
      .catch((error) => {
        console.error('Error starting the washing machine:', error);
        setAlertMessage('Кір жуғыш машина іске қосылмады. Қайталап көріңіз.');
        setAlertSeverity('error');
        setAlertOpen(true);
      });
  };

  // Закрытие алерта
  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <div className="App">
      <div className="logo-container">
        <img src="logo.png" alt="Logo" className="App-logo" /> {/* Замените путь на ваш логотип */}
      </div>
      <header className="App-header warning-message">
        <Typography variant="body1" className="">
          Егер кір жуу процесі басталмаса, "СТАРТ" түймесін басыңыз
        </Typography>
      </header>
      <Paper className="machine-control">
        <Typography variant="h5" gutterBottom>
          Машина ID: {machineId}
        </Typography>
        {machineData ? (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={handleStart}
              disabled={machineData.output.isDoorOpen} // Блокируем кнопку, если isDoorOpen = 1
              className={machineData.output.isDoorOpen ? 'button-disabled' : 'button-start'} // Применяем стиль disabled
            >
              СТАРТ
            </Button>
            <Typography variant="body1" className="machine-status">
              {machineData.output.isDoorOpen ? 'Жуу жүріп жатыр...' : 'Бос немесе есік ашық'}
            </Typography>
          </>
        ) : (
          <Typography variant="body1">
            Деректерді жүктеу...
          </Typography>
        )}
      </Paper>

      {/* Алерт для сообщений */}
      <Snackbar 
        open={alertOpen} 
        autoHideDuration={4000} 
        onClose={handleAlertClose} 
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
        className="custom-snackbar"
      >
        <Alert onClose={handleAlertClose} severity={alertSeverity} sx={{ width: '100%' }} className="alert-message">
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default App;
